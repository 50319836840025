import * as React from 'react';
import { Title } from './Title';
import classNames from 'classnames';
import { useState } from 'react';
import { Button } from './Button';
import { useContext } from 'react';
import { GlobalContext } from './Layout';

const CardService = ({ title, name, text, list, price, priceInfo }) => {
    const { setTitlePopup, setNameForm } = useContext(GlobalContext);
    const [isShow, setIsShow] = useState(false);
    return (
        <div className="card-service">
            <div className="card-service__name">{name}</div>
            <Title className="h1 card-service__title" level="h2">
                {title}
            </Title>
            {text && <p className="card-service__text">{text}</p>}
            {list && (
                <>
                    <div
                        className={classNames('card-service__list', {
                            active: isShow,
                        })}
                    >
                        <div className="card-service__list-title">В услугу входит:</div>
                        <ul className="card-service__list-items">
                            {list.map((item) => (
                                <li key={item} className="card-service__list-item">
                                    <svg
                                        className="card-service__list-item-icon"
                                        width="12"
                                        height="14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M.147.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.501.501 0 0 1-.708-.708L5.794 7 .147 1.354a.5.5 0 0 1 0-.708Z"
                                            fill="#333"
                                        />
                                        <path
                                            d="M4.147.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.794 7 4.147 1.354a.5.5 0 0 1 0-.708Z"
                                            fill="#333"
                                        />
                                    </svg>
                                    <span>{item}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )}

            <div className="card-service__footer">
                <div className="card-service__price">
                    {price}
                    <div className='card-service__price-info'>{priceInfo}</div>
                </div>
                <div className="card-service__wrap-btns">
                    <Button
                        onClick={() => {
                            setTitlePopup(title);
                            setNameForm('price_page');
                        }}
                        fancybox="dialog"
                        src="#form-popup"
                    >
                        Заказать услугу
                    </Button>
                    {list && (
                        <Button type="ghost" onClick={() => setIsShow((prevState) => !prevState)}>
                            {isShow ? 'Скрыть' : 'Подробнее'}{' '}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export { CardService };
