import * as React from 'react';
import { Layout } from '../components/Layout';
import { Form } from '../components/Form';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Title } from '../components/Title';
import { CardService } from '../components/CardService';
import { Seo } from '../components/Seo';
import { Offer } from '../components/Offer';

function IndexSite({ location }) {
    return (
        <Layout location={location}>
            <Breadcrumbs
                crumbs={[
                    ['/', 'Главная'],
                    [null, 'Услуги и цены'],
                ]}
            />
            <div className="container">
                <Title className="h1" level="h1">
                    Услуги и цены
                </Title>

            </div>
            <div className="container section-margin">
                <div className="wrapper-card-services">
                    <div className="process">
                        <div className="process__title">Как мы работаем</div>
                        <div className="process__wrap-items">
                            <div className="process__item">
                                <div className="process__item-num">1</div>
                                <div className="process__item-text">Консультируем по сотрудничеству и закупкам</div>
                            </div>
                            <div className="process__item">
                                <div className="process__item-num">2</div>
                                <div className="process__item-text">
                                    Согласовываем решения об участии в закупках и сотрудничестве
                                </div>
                            </div>
                            <div className="process__item">
                                <div className="process__item-num">3</div>
                                <div className="process__item-text">
                                    Собираем необходимые документы для участия в тендерах
                                </div>
                            </div>
                            <div className="process__item">
                                <div className="process__item-num">4</div>
                                <div className="process__item-text">Подготавливаем и подаем заявки</div>
                            </div>
                            <div className="process__item">
                                <div className="process__item-num">5</div>
                                <div className="process__item-text">Контроль участия и итогов</div>
                            </div>
                            <div className="process__item">
                                <div className="process__item-num">6</div>
                                <div className="process__item-text">Подписание контракта/договора в случае победы</div>
                            </div>
                            <div className="process__item">
                                <div className="process__item-num">7</div>
                                <div className="process__item-text">
                                    Электронное актирование исполнения контракта/договора
                                </div>
                            </div>
                        </div>
                    </div>
                    <CardService
                        name="Разовая услуга"
                        title="Подача одной заявки"
                        text="Для тех, кто хочет начать работу с государственными заказчиками"
                        list={[
                            'Консультация по выбранной закупке (возможность участия, целесообразность, стоимость участия);',
                            'Анализ документации по выбранной закупке (предоставление списка необходимых документов для участия);',
                            'Направление запроса заказчику по документации;',
                            'Подготовка пакета документов для подачи заявки;',
                            'Подача заявки на ЭТП;',
                            'Контроль промежуточных протоколов и итогов закупки;',
                            'Направление запроса разъяснений по итогам закупки;',
                            'В случае победы контроль направления и подписания проекта контракта/договора;',
                            'Составление протокола разногласий и направление его на рассмотрение заказчику, в случае выявления оснований;',
                            'Электронное актирование при исполнении контракта/договора;',
                            'Составление и подача жалобы в ФАС при необходимости;',
                        ]}
                        price="5 000 руб"
                    />
                    <CardService
                        name="Разовая услуга"
                        title="Регистрация в ЕРУЗ"
                        text="Аккредитация на 8-ми федеральных ЭТП в соответствии с требованиями Федерального закона ФЗ№44"
                        list={[
                            'Подготовка документов для регистрации;',
                            'Регистрация;',
                            'Аккредитация на 8-ми федеральных ЭТП в соответствии с требованиями Федерального закона ФЗ№4;',
                        ]}
                        price="3 000 руб"
                    />
                    <CardService
                        name="Разовая услуга"
                        title="Аккредитация на ЭТП"
                        text="Аккредитация на любой выбранной коммерческой ЭТП"
                        list={[
                            'Подготовка документов для регистрации;',
                            'Регистрация на ЭТП;',
                            'Загрузка электронно-цифровой подписи;',
                            'Загрузка документов в репозиторий ЭТП;',
                            'Аккредитация на ЭТП;',
                            'Настройка личного кабинета;',
                        ]}
                        price="3 000 руб"
                    />
                    <CardService
                        name="Разовая услуга"
                        title="Коллективная заявка"
                        text="Подача коллективной заявки в соответствии с ФЗ №223"
                        list={[
                            'Консультация по выбранной закупке (возможность участия, целесообразность, стоимость участия). Консультация по подбору оптимальных участников для коллектива, для обеспечения победы в тендере;',
                            'Анализ документации по выбранной закупке (предоставление списка необходимых документов для участия);',
                            'Направление запроса заказчику по документации;',
                            'Подготовка пакета документов для подачи заявки;',
                            'Составление договора коллективного участия;',
                            'Подача заявки на ЭТП;',
                            'Контроль промежуточных протоколов и итогов закупки;',
                            'Направление запроса разъяснений по итогам закупки;',
                            'В случае победы контроль направления и подписания проекта контракта/договора;',
                            'Составление протокола разногласий и направление его на рассмотрение заказчику, в случае выявления оснований;',
                            'Электронное актирование при исполнении контракта/договора;',
                            'Составление и подача жалобы в ФАС при необходимости;',
                        ]}
                        price="от 10 000 руб"
                    />
                    <CardService
                        name="Сопровождение на месяц"
                        title="Все включено на 1 месяц"
                        list={[
                            'Мониторинг и поиск тендеров в соответствии с выбранным направлением;',
                            'Консультация по выбранной закупке (возможность участия, целесообразность, стоимость участия);',
                            'Направление запроса разъяснений заказчику;',
                            'Анализ документации по выбранной закупке (предоставление списка необходимых документов для участия);',
                            'Аккредитация на ЭТП для выбранного тендера;',
                            'Подготовка и подача 10-ти любых заявок в месяц;',
                            'Подготовка пакета документов для подачи заявок на ЭТП;',
                            'Подача заявок на ЭТП;',
                            'Контроль промежуточных протоколов и итогов закупки;',
                            'Направление запроса разъяснений по итогам закупки;',
                            'В случае победы контроль направления и подписания проекта контракта/договора;',
                            'Составление протокола разногласий и направление его на рассмотрение заказчику, в случае выявления оснований;',
                            'Электронное актирование при исполнении контракта/договора;',
                            'Составление и подача жалобы в ФАС при необходимости;',
                        ]}
                        price="25 000 руб"
                    />
                    <CardService
                        name="Разовая услуга"
                        title="Регистрация в ГИСП"
                        list={[
                            'Консультация по порталу ГИСП;',
                            'Регистрация в системе ГИСП (государственной информационной системе промышленности);',
                            'Внесение продукции в реестр Российской промышленной продукции;',
                        ]}
                        price="20 000 руб"
                    />
                    <CardService
                        name="Разовая услуга"
                        title="Обучение (индивидуально)"
                        list={[
                            'Разъяснение сферы закупок, отличие государственных и коммерческих закупок;',
                            'Возможность вашей организации участия в тендере, подготовка базового комплекта документов для участия;',
                            'Предоставление информации об увеличении шансов на победу вашей организации (при необходимости помощь в составлении дополнительных документов);',
                            'Обучение подачи заявки на ЭТП;',
                        ]}
                        price="25 000 руб. за 7 уроков"
                        priceInfo="В комплект входит 3 урока предоставления теории закупок + 4 урока практических занятий на любой выбранной вами ЭТП."
                    />
                </div>
            </div>
            <div className="container section-margin">
                <Offer />
            </div>
            <Form />
        </Layout>
    );
}

export default IndexSite;

export function Head() {
    return (
        <Seo
            title={`Сопровождение тендеров "под ключ" - услуги по сопровождению закупок и тендеров`}
            description="Оказание услуг по сопровождению тендеров от компании ГЛАВТОРГИ. Услуги, связанные с закупками для бизнеса: подача заявки, регистрация участников закупов, комплексное тендерное сопровождение."
        />
    );
}
