import * as React from 'react';
import { Title } from './Title';
import { Button } from './Button';
import { useContext } from 'react';
import { GlobalContext } from './Layout';

const Offer = () => {
    const { setTitlePopup, setNameForm } = useContext(GlobalContext);
    return (
        <div className="offer section-margin">
            <div className="offer__left">
                <Title className="h1 offer__title" level="h2">
                    Специальное предложение
                </Title>
                <ul className="offer__items">
                    <li className="offer__item">
                        <svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#a)">
                                <path
                                    d="M24.268 7.675 11.079 20.863a2.503 2.503 0 0 1-3.538 0L.733 14.055a2.502 2.502 0 0 1 3.538-3.54l5.04 5.04L20.729 4.138a2.502 2.502 0 0 1 3.539 3.538Z"
                                    fill="var(--accent-color)"
                                />
                            </g>
                            <defs>
                                <clipPath id="a">
                                    <path fill="#fff" d="M0 0h25v25H0z" />
                                </clipPath>
                            </defs>
                        </svg>
                        Скидка 50% на первую подготовку и подачу заявки
                    </li>
                </ul>
            </div>
            <div className="offer__right">
                <Button
                    onClick={() => {
                        setTitlePopup('Получить специльное предложение');
                        setNameForm('special_offer');
                    }}
                    fancybox="dialog"
                    src="#form-popup"
                >
                    Получить
                </Button>
            </div>
        </div>
    );
};

export { Offer };
